import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import MainLayout from '@/components/core/MainLayout';
import Page from '@/components/core/Page';
import MainBestPriceSection from '@/components/main/MainBestPriceSection';
import MainChartSection from '@/components/main/MainChartSection';
import MainPartnersSection from '@/components/main/MainPartnersSection';
import MainPortfolioSection from '@/components/main/MainPortfolioSection';
import MainProChartSection from '@/components/main/MainProChartSection';
import MainStatistics from '@/components/main/MainStatisticsSection';
import { fetchInversedForex } from '@/lib/forex';
import { fetchTokenStatsV2, TokenStatsV2 } from '@/lib/token-stats-v2';

import type { Token } from '@/lib/tokens';
import type { GetStaticProps } from 'next';

export interface MainPageProps {
  stats: TokenStatsV2[] | null;
  tokenAddressToToken: { [key: string]: Token } | null;
}

export default function MainPage(props: MainPageProps) {
  return (
    <Page>
      <MainChartSection {...props} />
      <MainStatistics />
      <MainBestPriceSection />
      <MainProChartSection />
      <MainPortfolioSection />
      <MainPartnersSection />
    </Page>
  );
}

export const getStaticProps: GetStaticProps<MainPageProps> = async ({ locale }) => {
  try {
    const [stats, tokenAddressToToken, forex] = await Promise.all([
      fetchTokenStatsV2(),
      fetch(`${process.env.API_HOST}/v0/tokens`).then(
        (res) => res.json() as Promise<{ [address: string]: Token }>,
      ),
      fetchInversedForex().catch((err) => {
        console.log('Error fetching forex', err);
        return null;
      }),
    ]);

    return {
      props: {
        stats,
        tokenAddressToToken,
        forex,
        ...(await serverSideTranslations(locale!, ['homeV2', 'common'])),
      },
      /** @see https://nextjs.org/docs/basic-features/data-fetching/incremental-static-regeneration */
      revalidate: 10, // 10 seconds
    };
  } catch (err) {
    // TODO: can we send log to google cloud logging stream?
    console.error('failed to execute getStaticProps in main page', err);

    // send fallback data so that the page doesn't break even if the API is down
    return {
      props: {
        stats: null,
        tokenAddressToToken: null,
        ...(await serverSideTranslations(locale!, ['homeV2', 'common'])),
      },
      revalidate: 10, // 10 seconds
    };
  }
};

MainPage.Layout = MainLayout;
