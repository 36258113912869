interface Props {
  className?: string;
}

export default function MainMoon({ className }: Props) {
  return (
    <svg
      className={className}
      width="1237"
      height="1237"
      viewBox="0 0 1237 1237"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <g opacity="0.6">
        <path
          d="M618.5 1237C960.088 1237 1237 960.088 1237 618.5C1237 276.912 960.088 0 618.5 0C276.912 0 0 276.912 0 618.5C0 960.088 276.912 1237 618.5 1237Z"
          fill="#080B11"
        />
        <path
          d="M618.5 1237C960.088 1237 1237 960.088 1237 618.5C1237 276.912 960.088 0 618.5 0C276.912 0 0 276.912 0 618.5C0 960.088 276.912 1237 618.5 1237Z"
          fill="url(#background_linear)"
          fillOpacity="0.2"
        />
        <path
          className="mix-blend-color-dodge"
          d="M618.5 1237C960.088 1237 1237 960.088 1237 618.5C1237 276.912 960.088 0 618.5 0C276.912 0 0 276.912 0 618.5C0 960.088 276.912 1237 618.5 1237Z"
          fill="url(#paint0_radial_716_2762)"
        />
        <path
          className="mix-blend-overlay"
          d="M618.5 1237C960.088 1237 1237 960.088 1237 618.5C1237 276.912 960.088 0 618.5 0C276.912 0 0 276.912 0 618.5C0 960.088 276.912 1237 618.5 1237Z"
          fill="url(#paint1_linear_716_2762)"
        />
      </g>
      <defs>
        <linearGradient
          id="background_linear"
          x1="749"
          y1="0"
          x2="749"
          y2="653.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#123147" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint0_radial_716_2762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(626.909 644.302) rotate(8.01854) scale(639.338 644.052)"
        >
          <stop offset="0.877522" stopColor="#DEDEDF" stopOpacity="0.01" />
          <stop offset="0.94598" stopColor="#E2E2E3" stopOpacity="0.16" />
          <stop offset="0.973104" stopColor="#E9E9EA" stopOpacity="0.3" />
          <stop offset="0.994698" stopColor="#F4F4F4" stopOpacity="0.54" />
          <stop offset="1" stopColor="white" stopOpacity="0.6" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_716_2762"
          x1="786.489"
          y1="1117.46"
          x2="421.815"
          y2="33.8904"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.604167" stopColor="#DEDEDF" stopOpacity="0" />
          <stop offset="0.692708" stopColor="#E0E0E1" stopOpacity="0.01" />
          <stop offset="0.755208" stopColor="#E5E5E5" stopOpacity="0.05" />
          <stop offset="0.88364" stopColor="#EDEDEE" stopOpacity="0.11" />
          <stop offset="0.955181" stopColor="#F9F9F9" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0.24" />
        </linearGradient>
      </defs>
    </svg>
  );
}
