interface Props {
  className?: string;
}

export default function MainArrow({ className }: Props) {
  return (
    <svg
      className={className}
      width="140"
      height="157"
      viewBox="0 0 140 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <rect x="26.2363" y="43.4807" width="87.7816" height="56.6068" fill="currentColor" />
      <rect x="26.2363" y="20.5098" width="87.7816" height="19.6893" fill="currentColor" />
      <rect x="26.2363" y="8.20386" width="87.7816" height="9.02427" fill="currentColor" />
      <rect x="26.2363" width="87.7816" height="4.92233" fill="currentColor" />
      <path
        d="M137.515 100H1.9187C1.28008 100 0.996736 100.803 1.494 101.204L69.2922 155.837C69.5401 156.037 69.8937 156.037 70.1416 155.837L137.94 101.204C138.437 100.803 138.154 100 137.515 100Z"
        fill="currentColor"
      />
    </svg>
  );
}
