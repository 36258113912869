import clsx from 'clsx';
import moment from 'moment-timezone';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { SplineChart } from '@/components/SplineChart';
import TokenIconV2 from '@/components/TokenIconV2';
import MainMoon from '@/components/vector/MainMoon';
import SwapOutlined from '@/components/vector/SwapOutlined';
import { CURRENCY_ADDRESS_MAP, useCurrencyContext } from '@/context/currency';
import { SCNR_ADDRESS, ZERO_ADDRESS, STABLE_TOKENS, GCKLAY_ADDRESS } from '@/defines/token-address';
import useForex from '@/hooks/use-forex';
import { useTokenStatsV2 } from '@/hooks/use-token-stats-v2';
import useTokens from '@/hooks/use-tokens';
import { subscribePrices, SubscribePricesProps } from '@/lib/subscribe-prices';
import { getSafeTimezone } from '@/utils/moment';
import sparklePriceChange from '@/utils/sparklePriceChange';

import s from './MainChartSection.module.css';

import type { LineData } from '@/lib/spline';
import type { Token } from '@/lib/tokens';
import type { MainPageProps } from '@pages/index';

export type ChartTokenData = {
  token: Token | null;
  price: number | null;
  diffRatio: number | null;
  splines: LineData[] | null;
};

const CHART_TOKENS = [ZERO_ADDRESS, GCKLAY_ADDRESS, SCNR_ADDRESS];

type MainChartSectionProps = Pick<MainPageProps, 'stats' | 'tokenAddressToToken'>;

export default function MainChartSection({
  stats: _stats,
  tokenAddressToToken: _tokenAddressToToken,
}: MainChartSectionProps) {
  const { t: th } = useTranslation('homeV2');
  const { t: tc } = useTranslation('common');
  const { currency } = useCurrencyContext();
  const { usdToReadableCurrency } = useForex();
  const router = useRouter();

  const { tokenAddressToToken } = useTokens();
  const { tokenStats, mutate: mutateTokenStats } = useTokenStatsV2({
    fallbackData: _stats ?? [],
    revalidateOnFocus: false,
    excludeImaginaryFiats: true,
  });

  // display CHART_TOKENS and poppuar tokens by volume
  const tokenAddrsToDisplay = useMemo(() => {
    return tokenStats
      .filter(({ address }) => !STABLE_TOKENS.has(address))
      .sort((a, b) => {
        if (CHART_TOKENS.includes(a.address) && CHART_TOKENS.includes(b.address)) {
          return CHART_TOKENS.indexOf(a.address) - CHART_TOKENS.indexOf(b.address);
        }
        if (CHART_TOKENS.includes(a.address)) return -1;
        if (CHART_TOKENS.includes(b.address)) return 1;
        return b.volume - a.volume;
      })
      .slice(0, 9)
      .map(({ address }) => address);
  }, [tokenStats]);

  useEffect(() => {
    const handler: SubscribePricesProps['handler'] = ({ timestamp, prices }) => {
      mutateTokenStats(
        (prev) => {
          if (!prev) return;
          const _prevTokenStats = prev;

          return _prevTokenStats.map((tokenStats) =>
            tokenStats.address in prices
              ? { ...tokenStats, prices: [tokenStats.prices[0], prices[tokenStats.address]] }
              : tokenStats,
          );
        },
        { revalidate: false, populateCache: true },
      );
    };

    let unsubscriber: (() => void) | undefined;
    subscribePrices({ tokenAddressList: tokenAddrsToDisplay, handler }).then((_unsubscriber) => {
      unsubscriber = _unsubscriber;
    });

    return () => {
      unsubscriber && unsubscriber();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateTokenStats, tokenAddrsToDisplay.join('.')]); // prevent re-subscribe on mutateTokenStats

  const tokenMap = useMemo(() => {
    if (Object.keys(tokenAddressToToken).length === 0) {
      return _tokenAddressToToken ?? {};
    }

    return tokenAddressToToken;
  }, [tokenAddressToToken, _tokenAddressToToken]);

  const filteredTokenStats = useMemo<ChartTokenData[]>(() => {
    if (tokenStats.length === 0 || Object.keys(tokenMap).length === 0) {
      return Array.from({ length: tokenAddrsToDisplay.length }, () => ({
        token: null,
        price: null,
        diffRatio: null,
        splines: null,
      }));
    }

    return tokenAddrsToDisplay
      .filter((addr) => {
        if (tokenStats.findIndex(({ address }) => addr === address) === -1) return false;
        if (!tokenMap[addr]) return false;

        return true;
      })
      .map((addr) => {
        const { splines, price, priceDiffRatio } = tokenStats.find(
          ({ address }) => addr === address,
        )!;

        return {
          token: tokenMap[addr],
          price,
          diffRatio: priceDiffRatio,
          splines: splines ?? null,
        };
      });
  }, [tokenStats, tokenMap, tokenAddrsToDisplay]);

  const prevTokenStats = useRef<any>();

  useEffect(() => {
    if (!prevTokenStats.current) return;

    for (const prev of prevTokenStats.current) {
      const cur = filteredTokenStats.find(({ token }) => prev.token?.address === token?.address);
      if (!cur || !cur.token || !cur.price) return;

      const diff = Math.round(((cur.price - prev.price) / prev.price) * 1e6) / 1e6;

      sparklePriceChange({
        address: prev.token?.address,
        diff,
      });
    }
  }, [filteredTokenStats]);

  useEffect(() => {
    prevTokenStats.current = tokenStats;
  }, [tokenStats]);

  const [dataInfoText, setDataInfoText] = useState<string | null>(null);
  useEffect(() => {
    const timezone = getSafeTimezone();

    const today = moment().utc().startOf('day');
    setDataInfoText(
      th('Since {{Date}}', {
        Date: `${today.tz(timezone).format('YYYY-MM-DD HH:mm')} ${moment.tz(timezone).format('z')}`,
      }),
    );
  }, [th]);

  const gotoProChart = useCallback(
    (address?: string) => {
      if (!address) return;

      router.push({
        pathname: '/pro/swap',
        query: {
          ...router.query,
          from: CURRENCY_ADDRESS_MAP[currency],
          to: address,
        },
      });
    },
    [router, currency],
  );

  return (
    <section className="bg-white dark:bg-gray-900">
      <div
        className="relative overflow-hidden pt-16 md:pt-32 pb-48"
        style={{
          background: `linear-gradient(162.54deg, rgba(18, 49, 71, 0.2) 8.45%, rgba(0, 0, 0, 0) 83.57%), #080B11`,
        }}
      >
        <MainMoon className="absolute top-80 md:top-72 left-1/2 -translate-x-1/2 w-[1237px] aspect-1" />
        <div className="px-12 relative flex flex-col items-center">
          <h2 className="text-white text-[28px] md:text-[48px] text-center font-bold md:leading-slug max-w-md md:max-w-xl">
            <Trans t={th} i18nKey="main-chart-title" />
          </h2>
          <p className="text-white text-center mt-10 leading-7 max-w-xl px-4">
            <Trans
              t={th}
              i18nKey="main-chart-description"
              components={{
                color: (
                  <span className="font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#3EB9FF] to-[#2AC3A8]" />
                ),
              }}
            />
          </p>
          <div className="h-[52px] mt-10 flex justify-center space-x-2.5">
            <Link
              href={{
                pathname: '/swap',
                query: { from: ZERO_ADDRESS, to: SCNR_ADDRESS },
              }}
              passHref
            >
              <a className={clsx(s['chart-section-button'], 'bg-teal-500 text-white')}>
                <SwapOutlined className="mr-2" />
                <div className="hidden sm:block">{th('Swap with Navigator')}</div>
                <div className="block sm:hidden">{th('Swap')}</div>
              </a>
            </Link>
            <a
              className={clsx(s['chart-section-button'], 'bg-gray-900 text-gray-300')}
              href={tc<string>('T-docs-link')}
            >
              Docs
            </a>
          </div>
        </div>
      </div>
      <div className="relative px-5 pb-52 flex flex-col items-center">
        <div
          className={clsx(
            s['main-chart-shadow'],
            '-mt-36 rounded-xl w-full max-w-md sm:max-w-[600px] lg:max-w-[880px] bg-white dark:bg-gray-900',
          )}
        >
          <div>
            <table className="min-w-full">
              <thead>
                <tr>
                  <th
                    className={clsx(s['token-table-heading'], 'text-left pt-8 pb-4 pl-7 xs:pl-8')}
                  >
                    {th('Token')}
                  </th>
                  <th
                    className={clsx(
                      s['token-table-heading'],
                      'min-[390px]:px-2 min-[390px]:w-[115px] min-[430px]:pr-5 min-[430px]:w-[140px] min-[480px]:px-8 min-[480px]:w-44',
                      'text-right px-8 pt-8 pb-4',
                    )}
                  >
                    {th('Price')}
                  </th>
                  <th
                    className={clsx(
                      s['token-table-heading'],
                      'min-[390px]:table-cell min-[480px]:pl-4 min-[480px]:w-[120px]',
                      'hidden pt-8 pb-4 pl-2 pr-7 xs:pl-8 w-[105px] text-right',
                    )}
                  >
                    <span>{th('Change')}</span>
                  </th>
                  <th
                    className={clsx(
                      s['token-table-heading'],
                      'text-left hidden px-8 pt-8 pb-4 sm:table-cell',
                    )}
                  >
                    <span>24h</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredTokenStats.map(({ token, price, diffRatio, splines }, idx) => (
                  <tr
                    onClick={() => {
                      gotoProChart(token?.address);
                    }}
                    className="hover:cursor-pointer hover:opacity-70"
                    key={`token-${idx}`}
                  >
                    <td className={clsx(s['token-table-data'], 'pl-7 xs:pl-8')}>
                      <div className="flex items-center space-x-2">
                        <TokenIconV2
                          address={token?.address}
                          className="border dark:border-0 border-gray-200 w-8 h-8"
                          width={32}
                          height={32}
                        />
                        <span className="text-gray-800 dark:text-white font-medium text-sm md:text-base">
                          {token?.symbol ?? '-'}
                        </span>
                        <span className="text-sm text-gray-500 hidden lg:inline whitespace-nowrap">
                          {token?.name ?? '-'}
                        </span>
                      </div>
                    </td>
                    <td
                      className={clsx(
                        s['token-table-data'],
                        'min-[390px]:px-2 min-[390px]:w-[115px] min-[430px]:pr-5 min-[430px]:w-[140px] min-[480px]:px-8 min-[480px]:w-44',
                        'text-right text-sm text-gray-800 dark:text-white w-[140px] pl-2 pr-7 xs:pl-8',
                      )}
                    >
                      <div className={clsx(`price_${token?.address}`, 'price-spark')}>
                        {usdToReadableCurrency(price)}
                      </div>
                    </td>
                    <td
                      className={clsx(
                        s['token-table-data'],
                        'min-[390px]:table-cell min-[480px]:pl-4 min-[480px]:w-[120px]',
                        'text-sm hidden pl-2 pr-7 xs:pl-8 w-[105px] text-right',
                      )}
                    >
                      <span
                        className={clsx({
                          'text-green-500': diffRatio !== null && diffRatio > 0,
                          'text-red-500': diffRatio !== null && diffRatio < 0,
                          'text-gray-500': !diffRatio,
                        })}
                      >
                        {diffRatio === null
                          ? '-'
                          : diffRatio.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                              signDisplay: 'always',
                              style: 'percent',
                            })}
                      </span>
                    </td>
                    <td
                      className={clsx(
                        s['token-table-data'],
                        'whitespace-nowrap hidden sm:table-cell px-8 w-[154px]',
                      )}
                    >
                      {splines === null || splines.length === 0 || !price ? (
                        <span className="w-[90px] h-[35px] mx-auto" aria-hidden="true">
                          -
                        </span>
                      ) : (
                        <SplineChart
                          className="mx-auto"
                          data={splines} // add fresh data to last point
                          width={90}
                          height={35}
                          color={
                            diffRatio !== null && diffRatio > 0
                              ? '#22C55E'
                              : diffRatio !== null && diffRatio < 0
                              ? '#EF4444'
                              : '#6B7280'
                          }
                          gradientOpacity={0.35}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex justify-end mt-4 mb-8 px-5 md:px-8">
              <span className="text-sm text-gray-400">{dataInfoText}</span>
            </div>
          </div>
        </div>
        <div className="flex items-center mt-[44px] sm:mt-20">
          <Link href="/tokens">
            <a
              className={clsx(
                'mx-auto text-lg py-3 px-[18px] rounded-lg border',
                'border-gray-200 text-gray-500 bg-white hover:bg-gray-50',
                'dark:border-gray-700 dark:bg-gray-900 dark:text-gray-400 hover:dark:bg-gray-800',
              )}
            >
              {th('View all tokens')}
            </a>
          </Link>
        </div>
      </div>
    </section>
  );
}
