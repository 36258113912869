interface Props {
  className?: string;
}

export default function ActivityIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M17.8267 8.97775H14.5911L12.1645 16.2578L7.31115 1.69775L4.88448 8.97775H1.64893"
        stroke="currentColor"
        strokeWidth="1.61778"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
