import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';

import type { ReactNode } from 'react';

interface MotionContainerProps {
  children: ReactNode;
  className?: string;
  direction?: 'up' | 'down';
}

export default function MotionContainer({
  className,
  children,
  direction = 'up',
}: MotionContainerProps) {
  const { ref, inView } = useInView({
    threshold: 0.4,
    delay: 300,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className={clsx(className, 'transition ease-out duration-700', {
        'opacity-0 translate-y-10': !inView && direction === 'up',
        'opacity-0 -translate-y-10': !inView && direction === 'down',
        'opacity-100 translate-y-0': inView,
      })}
    >
      {children}
    </div>
  );
}
