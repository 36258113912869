import NextImage from 'next/image';
import { Trans, useTranslation } from 'next-i18next';

import MotionContainer from '@/components/main/MotionContainer';
import portfolioImageDark from '@/images/home-illust-portfolio-dark.png';
import portfolioImageLight from '@/images/home-illust-portfolio-light.png';

import cs from './common.module.css';

export default function MainPortfolioSection() {
  const { t: th } = useTranslation('homeV2');

  return (
    <section className="dark:bg-gray-950 overflow-hidden">
      <div className={cs.wrapper}>
        <MotionContainer className={cs.content}>
          <h3 className={cs.summary}>{th('Portfolio')}</h3>

          <h2 className={cs.title}>
            <Trans t={th} i18nKey="main_portfolio_summary_title" />
          </h2>
          <p className={cs.description}>
            <Trans t={th} i18nKey="main_portfolio_summary_description" />
          </p>
        </MotionContainer>

        <MotionContainer className={cs['section-image']}>
          <div className="hidden dark:block">
            <NextImage
              src={portfolioImageDark}
              className="hidden dark:block"
              placeholder="blur"
              draggable="false"
            />
          </div>
          <div className="block dark:hidden">
            <NextImage
              src={portfolioImageLight}
              className="block dark:hidden"
              placeholder="blur"
              draggable="false"
            />
          </div>
        </MotionContainer>
      </div>
    </section>
  );
}
