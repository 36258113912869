import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

import MotionContainer from '@/components/main/MotionContainer';

const partners = [
  { name: 'krust', link: 'https://www.krustuniverse.com/' },
  { name: 'ground-x', link: 'https://www.groundx.xyz/' },
  { name: 'marblex', link: 'https://www.marblex.io/en' },
  { name: 'kracker-labs', link: 'https://www.krackerlabs.io/' },
  { name: 'klip', link: 'https://klipwallet.com/' },
  { name: 'kaikas', link: 'https://docs.kaikas.io/' },
  { name: 'dcent', link: 'https://dcentwallet.com/' },
  { name: 'orbit-bridge', link: 'https://bridge.orbitchain.io/' },
  { name: 'klayswap', link: 'https://klayswap.com/' },
  { name: 'pangea', link: 'https://www.pangeaswap.com/' },
  { name: 'kokonut-swap', link: 'https://kokonutswap.finance/' },
  { name: 'ufo-swap', link: 'https://ufoswap.fi/#/' },
  { name: 'claim-swap', link: 'https://claimswap.org/' },
  { name: 'definix', link: 'https://definix.com/en' },
  { name: 'taal-swap', link: 'https://taalswap.finance/' },
  { name: 'kai-protocol', link: 'https://kaiprotocol.fi/' },
  { name: 'klayfi', link: 'https://klayfi.finance/' },
  { name: 'states-dao', link: 'https://statesdao.club/' },
  { name: 'klaytn-scope', link: 'https://scope.klaytn.com/' },
  { name: 'kns', link: 'https://klaytn.domains/' },
  { name: 'gemhub', link: 'https://www.gemhub.net/' },
  { name: 'kmint', link: 'https://www.kmintprotocol.io/' },
  { name: 'drawshop', link: 'https://drawshop.io/' },
  { name: 'fingerlabs', link: 'https://fingerlabs.io/' },
  { name: 'defi-kingdoms', link: 'https://defikingdoms.com/' },
  { name: 'bora', link: 'https://www.borachain.io/' },
  { name: 'per-project', link: 'https://www.perproject.io/' },
  { name: 'kleva', link: 'https://kleva.io/' },
  { name: 'superwalk', link: 'https://superwalk.io/' },
  { name: 'neopin', link: 'https://neopin.io/' },
  { name: 'dragon-swap', link: 'https://dgswap.io/' },
  { name: 'abc', link: 'https://myabcwallet.io/ko/' },
  { name: 'capybara', link: 'https://www.capybara.exchange/' },
  { name: 'kaiaswap', link: 'https://kaiaswap.org/home' },
  { name: 'aquaspace', link: 'https://bwpm.io/#/aqua-space' },
] as const;

export default function MainPartnersSection() {
  const { t: th } = useTranslation('homeV2');

  return (
    <section className="bg-gray-950 overflow-hidden">
      <div className="py-40">
        <MotionContainer className="px-8">
          <h2 className="text-white text-[40px] text-center font-bold">Partners</h2>
          <p className="text-white mt-5 text-center max-w-md mx-auto text-base">
            {th('main_partners_description')}
          </p>
        </MotionContainer>

        <MotionContainer
          className={clsx(
            'mt-24 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 place-items-center mx-auto max-w-fit',
            'gap-x-4 xs:gap-x-16 gap-y-4 sm:gap-y-5 md:gap-y-10 xl:gap-y-14',
          )}
        >
          {partners.flat().map(({ name, link }) => (
            <a
              href={link}
              target="_blank"
              key={`logo-desktop-${name}`}
              rel="noreferrer"
              draggable={false}
            >
              <img
                className="h-10 xs:h-12 xl:h-14 w-auto"
                src={`/partners/${name}.png`}
                alt={`${name}'s logo`}
                draggable={false}
              />
            </a>
          ))}
        </MotionContainer>
      </div>
    </section>
  );
}
