import { useSpring, a } from '@react-spring/web';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import MotionContainer from '@/components/main/MotionContainer';
import ActivityIcon from '@/components/vector/ActivityIcon';
import MainArrow from '@/components/vector/MainArrow';

import cs from './common.module.css';

export default function MainBestPriceSection() {
  const { t: th } = useTranslation('homeV2');
  const [{ amount }, api] = useSpring(() => ({ amount: 8.8888 }));

  useEffect(() => {
    const timer = setInterval(() => api.start({ amount: Math.random() * 9 + 1 }), 3_000);
    return () => clearInterval(timer);
  }, [api]);

  return (
    <section className="dark:bg-gray-950 overflow-hidden">
      <div className={cs.wrapper}>
        <MotionContainer className={cs.content}>
          <h3 className={cs.summary}>{th('main_best_price_guarantee_summary_main_title')}</h3>

          <h2 className={cs.title}>
            <Trans t={th} i18nKey="main_best_price_guarantee_summary_title" />
          </h2>
          <p className={`${cs.description} md:pr-20`}>
            <Trans t={th} i18nKey="main_best_price_guarantee_summary_description" />
          </p>
        </MotionContainer>
        <MotionContainer className="flex flex-col items-center">
          <div className="relative pl-4 pr-20 pt-36 md:py-36 flex justify-start">
            <div className="px-5 pb-6 pt-14 rounded-3xl min-w-[220px] bg-gray-50 dark:bg-gradient-to-br from-[#0C1018] to-[#171B25]">
              <ActivityIcon className="w-6 h-auto text-gray-700 dark:text-white" />
              <p className="text-lg text-gray-700 dark:text-white font-medium mt-4">
                {th('vs Other DEXes')}
              </p>
              <a.p className="text-3xl font-medium text-green-500 mt-2 mr-12 w-44">
                {amount.to((val) =>
                  th('Saves ${{amount}}', {
                    amount: val.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }),
                  }),
                )}
              </a.p>
            </div>
            <MainArrow className="absolute animate-bounce-slow top-28 right-0 text-green-500/90 drop-shadow-blurry" />
          </div>
        </MotionContainer>
      </div>
    </section>
  );
}
