import useSWRImmutable from 'swr/immutable';

type Stats = {
  linkedDexCount: number;
  swapVolumeUSDC: number;
  tokenCount: number;
  tvl: number;
};

const fallbackStats = {
  linkedDexCount: 0,
  swapVolumeUSDC: 0,
  tokenCount: 0,
  tvl: 0,
};

export function useMainStats() {
  const { data: stats = fallbackStats } = useSWRImmutable<Stats>(
    `${process.env.API_HOST}/v1/stats/main`,
    (key) => fetch(key).then((r) => r.json()),
    {
      refreshInterval: 60 * 1_000,
    },
  );

  return { stats };
}
