import clsx from 'clsx';
import { Trans, useTranslation } from 'next-i18next';

import MotionContainer from '@/components/main/MotionContainer';
import { InfoIcon } from '@/components/vector';
import { useCurrencyContext } from '@/context/currency';
import useForex from '@/hooks/use-forex';
import { useMainStats } from '@/hooks/use-main-stats';

import s from './MainStatisticsSection.module.css';

export default function MainStatistics() {
  const { usdToReadableSimpleString } = useForex();
  const { t: th } = useTranslation('homeV2');
  const { currencyType } = useCurrencyContext();
  const oUSDCCurrency = currencyType === 'KRW' ? 'KRW' : 'USD';

  const { stats } = useMainStats();

  return (
    <section className="bg-gradient-to-b from-white dark:from-gray-900 to-gray-100 dark:to-gray-950">
      <div className="flex flex-col items-stretch text-center py-[120px] md:py-40">
        <MotionContainer className="flex flex-col items-center px-4">
          <h2 className="text-gray-800 dark:text-white text-[28px] md:text-4xl text-center font-bold md:leading-slug max-w-xs md:max-w-xl">
            <Trans
              t={th}
              i18nKey="main-statistics-title"
              components={{
                br: <br className="md:hidden" />,
                color: <span className="text-teal-400" />,
              }}
            />
          </h2>
          <p className="mt-10 hidden md:block max-w-md text-gray-800 dark:text-white">
            {th('main-statistics-description')}
          </p>
        </MotionContainer>

        <MotionContainer className="w-full max-w-xl xl:max-w-5xl mx-auto mt-20 px-8 md:px-0">
          <dl className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6">
            <div className={clsx(s['stat-container'], 'flex flex-col justify-center items-center')}>
              <dt className={s['stat-title']}>
                {usdToReadableSimpleString(stats?.swapVolumeUSDC, { useKimp: true })}+
              </dt>
              <dd className={clsx(s['stat-description'], 'flex items-center space-x-1')}>
                <span>{th('Total Swap Volume')}</span>
                {/* <div
                  data-tooltip-id="global-tooltip"
                  data-tooltip-content={th(
                    'This amount is calculated based on an exchange rate of 1 oUSDC = $1',
                  )}
                  className="ml-1 p-1"
                >
                  <InfoIcon className="h-4 w-4 text-gray-500" />
                </div> */}
              </dd>
            </div>
            <div className={clsx(s['stat-container'], 'flex flex-col justify-center items-center')}>
              <dt className={s['stat-title']}>
                {usdToReadableSimpleString(stats?.tvl, { useKimp: true })}+
              </dt>
              <dd className={clsx(s['stat-description'], 'flex items-center space-x-1')}>
                {th('Total Liquidity')}

                {/* <div
                  data-tooltip-id="global-tooltip"
                  data-tooltip-content={th(
                    'This amount is calculated based on an exchange rate of 1 oUSDC = $1',
                  )}
                  className="ml-1 p-1"
                >
                  <InfoIcon className="h-4 w-4 text-gray-500" />
                </div> */}
              </dd>
            </div>
            <div className={s['stat-container']}>
              <dt className={s['stat-title']}>{stats?.tokenCount}</dt>
              <dd className={s['stat-description']}>{th('Tokens Supported')}</dd>
            </div>
            <div className={s['stat-container']}>
              <dt className={s['stat-title']}>{stats?.linkedDexCount}</dt>
              <dd className={s['stat-description']}>{th('# of DEX')}</dd>
            </div>
          </dl>
        </MotionContainer>
      </div>
    </section>
  );
}
